var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.label))]),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: _vm.screenSize < 600 ? "3" : "4" } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column align-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mb-4",
                    attrs: { text: "", color: "primary" },
                    on: { click: _vm.incrementHour },
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { size: _vm.arrowSize, color: "primary" } },
                      [_vm._v("keyboard_arrow_up")]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "time_text" }, [
                  _vm._v(
                    " " + _vm._s(`${_vm.timeData.hour}`.padStart(2, "0")) + " "
                  ),
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-4",
                    attrs: { text: "", color: "primary" },
                    on: { click: _vm.decrementHour },
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { size: _vm.arrowSize, color: "primary" } },
                      [_vm._v("keyboard_arrow_down")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "time_text d-flex align-center",
              staticStyle: { "font-weight": "bold" },
            },
            [_vm._v(":")]
          ),
          _c("v-col", { attrs: { cols: _vm.screenSize < 600 ? "3" : "4" } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column align-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mb-4",
                    attrs: { text: "", color: "primary" },
                    on: { click: _vm.incrementMinute },
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { size: _vm.arrowSize, color: "primary" } },
                      [_vm._v("keyboard_arrow_up")]
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "time_text" }, [
                  _vm._v(
                    " " +
                      _vm._s(`${_vm.timeData.minute}`.padStart(2, "0")) +
                      " "
                  ),
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-4",
                    attrs: { text: "", color: "primary" },
                    on: { click: _vm.decrementMinute },
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { size: _vm.arrowSize, color: "primary" } },
                      [_vm._v("keyboard_arrow_down")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-col",
            { staticClass: "d-flex justify-center align-center" },
            [
              _c(
                "v-btn-toggle",
                {
                  staticClass: "flex-column",
                  attrs: { group: "", mandatory: "", color: "primary" },
                  model: {
                    value: _vm.timeData.amPm,
                    callback: function ($$v) {
                      _vm.$set(_vm.timeData, "amPm", $$v)
                    },
                    expression: "timeData.amPm",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "time-toggle-btn rounded",
                      attrs: { value: "AM" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "xl:text-xl lg:text-base md:text-base sm:text-xs",
                        },
                        [_vm._v("AM")]
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "time-toggle-btn rounded",
                      attrs: { value: "PM" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "xl:text-xl lg:text-base md:text-base sm:text-xs",
                        },
                        [_vm._v("PM")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }