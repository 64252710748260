<template>
  <v-container>
    <div class="title">{{ label }}</div>
    <v-row>
      <v-col :cols="screenSize < 600 ? '3' : '4'">
        <div class="d-flex flex-column align-center">
          <v-btn text color="primary" class="mb-4" @click="incrementHour">
            <v-icon :size="arrowSize" color="primary">keyboard_arrow_up</v-icon>
          </v-btn>
          <span class="time_text">
            {{ `${timeData.hour}`.padStart(2, "0") }}
          </span>
          <v-btn text color="primary" class="mt-4" @click="decrementHour">
            <v-icon :size="arrowSize" color="primary"
              >keyboard_arrow_down</v-icon
            >
          </v-btn>
        </div>
      </v-col>
      <span class="time_text d-flex align-center" style="font-weight: bold"
        >:</span
      >
      <v-col :cols="screenSize < 600 ? '3' : '4'">
        <div class="d-flex flex-column align-center">
          <v-btn text color="primary" class="mb-4" @click="incrementMinute">
            <v-icon :size="arrowSize" color="primary">keyboard_arrow_up</v-icon>
          </v-btn>
          <span class="time_text">
            {{ `${timeData.minute}`.padStart(2, "0") }}
          </span>
          <v-btn text color="primary" class="mt-4" @click="decrementMinute">
            <v-icon :size="arrowSize" color="primary"
              >keyboard_arrow_down</v-icon
            >
          </v-btn>
        </div>
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <v-btn-toggle
          v-model="timeData.amPm"
          group
          mandatory
          color="primary"
          class="flex-column"
        >
          <v-btn value="AM" class="time-toggle-btn rounded">
            <span class="xl:text-xl lg:text-base md:text-base sm:text-xs">AM</span>
          </v-btn>

          <v-btn value="PM" class="time-toggle-btn rounded">
            <span class="xl:text-xl lg:text-base md:text-base sm:text-xs">PM</span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const now = new Date();
export default {
  name: "TimePicker",
  props: {
    label: {
      type: String,
      default: ""
    },
    time: {
      type: Object,
      default: () => ({
        hour:
          now.getHours() === 12 || now.getHours() === 0
            ? 12
            : now.getHours() % 12,
        minute: 0,
        amPm: now.getHours() <= 12 ? "AM" : "PM"
      })
    }
  },
  data() {
    return {
      timeData: { ...this.time }
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    arrowSize() {
      let s = this.screenSize;
      return s < 600 ? 16 : s < 900 ? 24 : s < 1601 ? 30 : 35;
    }
  },
  watch: {
    timeData: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("input", this.timeData);
      }
    },
    "timeData.hour"(value) {
      if (value === 0) {
        this.timeData.hour = 12;
      }
    }
  },
  created() {},
  methods: {
    incrementHour() {
      if (this.timeData.hour === 11) {
        this.timeData.hour = 12;
        return;
      }
      this.timeData.hour = (this.timeData.hour + 1) % 12;
    },
    decrementHour() {
      if (this.timeData.hour === 1) {
        this.timeData.hour = 12;
        return;
      }
      this.timeData.hour = (this.timeData.hour - 1) % 12;
    },
    incrementMinute() {
      this.timeData.minute = (this.timeData.minute + 15) % 60;
    },
    decrementMinute() {
      if (this.timeData.minute <= 0) {
        this.timeData.minute = 45;
        return;
      }
      this.timeData.minute = (this.timeData.minute - 15) % 60;
    }
  }
};
</script>

<style lang="scss" scoped>
.time_text {
  font-weight: 500;
  color: $text;

  @media screen and (min-width: 1601px) {
    font-size: 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}
.time-toggle-btn {
  min-width: 30px !important;

  @media screen and (min-width: 1601px) {
    height: 50px !important;
    width: 50px !important;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    height: 40px !important;
    width: 40px !important;
  }

  @media screen and (max-width: 599px) {
    height: 35px !important;
    width: 35px !important;
  }
}
</style>
